var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('CCardBody',{staticClass:"c-card-body"},[_c('router-link',{attrs:{"to":"/users/create"}},[_c('CButton',{attrs:{"color":"success"}},[_vm._v(" Создать пользователя ")])],1),_c('br'),_c('br'),_c('CDataTable',{attrs:{"items":_vm.users.items,"fields":_vm.fields,"hover":"","border":"","sorter":"","tableFilter":{ label: 'Поиск', placeholder: 'найти...' }},scopedSlots:_vm._u([{key:"first_name",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('router-link',{attrs:{"to":("/users/update/" + (item.id))}},[_vm._v(" "+_vm._s(item.first_name)+" ")])],1)]}},{key:"group",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(" "+_vm._s(_vm.getRoleLabel(item))+" ")])]}},{key:"phone",fn:function(ref){
var item = ref.item;
return [_c('td',[(item.phone)?_c('div',[_vm._v(" "+_vm._s(item.phone)+" ")]):_vm._e()])]}},{key:"orders",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(" "+_vm._s(item.is_superuser ? "Все" : "Только свои")+" ")])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }