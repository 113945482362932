<template>
  <div>
    <CCardBody class="c-card-body">
      <router-link :to="`/users/create`">
        <CButton color="success"> Создать пользователя </CButton>
      </router-link>
      <br />
      <br />
      <CDataTable
        :items="users.items"
        :fields="fields"
        hover
        border
        sorter
        :tableFilter="{ label: 'Поиск', placeholder: 'найти...' }"
      >
        <template #first_name="{ item }">
          <td>
            <router-link :to="`/users/update/${item.id}`">
              {{ item.first_name }}
            </router-link>
          </td>
        </template>
        <template #group="{ item }">
          <td>
            {{ getRoleLabel(item) }}
          </td>
        </template>
        <template #phone="{ item }">
          <td>
            <div v-if="item.phone">
              {{ item.phone }}
            </div>
          </td>
        </template>
        <template #orders="{ item }">
          <td>
            {{ item.is_superuser ? "Все" : "Только свои" }}
          </td>
        </template>
      </CDataTable>
    </CCardBody>
  </div>
</template>


<script>
import { mapState, mapActions } from "vuex";
import groups from "./groups";


const fields = [
  {
    key: "id",
    label: "ID",
  },
  {
    key: "first_name",
    label: "Пользователь",
  },
  {
    key: "email",
    label: "Почта",
  },
  {
    key: "phone",
    label: "Телефон",
  },
  {
    key: "group",
    label: "Роль",
  },
  {
    key: "orders",
    label: "Доступные заказы",
  },
];

export default {
  name: "Users",
  data() {
    return {
      fields,
      groups,
    };
  },

  computed: {
    ...mapState({
      users: (state) => state.users.filtered,
    }),
  },
  created() {
    this.getFiltered({ is_active: true });
  },
  methods: {
    ...mapActions("users", ["getFiltered"]),
    getRoleLabel(item) {
      const group = groups.filter((it) => item.groups[0] == it.value)[0];
      if (!group) {
        return "";
      }
      return group.label;
    },
  },
};
</script>
